/***************************************************
  Imports
***************************************************/

import React from "react"

import Button from "./Button"

import IconExcellence from "../images/icon-excellence.svg"
import IconSimplicity from "../images/icon-simplicity-of-design.svg"
import IconImportantAssets from "../images/icon-important-assets.svg"
import IconHonesty from "../images/icon-honesty.svg"

import IconManufacturing from "../images/Icon-Manufacturing.svg"
import IconDistrbution from "../images/Icon-Distribution.svg"
import IconProfessionalService from "../images/Icon-ProfessionalService.svg"
import IconFoodBanks from "../images/Icon-FoodBanks.svg"
import IconOtherIndustries from "../images/Icon-Other-industries.svg"

import IconAnalysis from "../images/icon-analysis-and-design.svg"
import IconDevelopment from "../images/icon-development.svg"
import IconProjectManagement from "../images/icon-project-management.svg"
import IconImplementation from "../images/icon-implementation.svg"
import IconTraining from "../images/icon-training-and-support.svg"
import IconSystemIntegration from "../images/icon-system-integration.svg"

import IconAnalysisFill from "../images/icon-analysis-and-design-fill.svg"
import IconDevelopmentFill from "../images/icon-development-fill.svg"
import IconProjectManagementFill from "../images/icon-project-management-fill.svg"
import IconImplementationFill from "../images/icon-implementation-fill.svg"
import IconTrainingFill from "../images/icon-training-and-support-fill.svg"
import IconSystemIntegrationFill from "../images/icon-system-integration-fill.svg"

/***************************************************
  Constants/Variables
***************************************************/

export const ICONS = {
  excellence: {
    src: IconExcellence,
    alt: "Excellence",
  },
  simplicity: {
    src: IconSimplicity,
    alt: "Simplicity",
  },
  importantAssets: {
    src: IconImportantAssets,
    alt: "Important Assets",
  },
  honesty: {
    src: IconHonesty,
    alt: "Honesty",
  },
  manufacturing: {
    src: IconManufacturing,
    alt: "Manufacturing",
  },
  distribution: {
    src: IconDistrbution,
    alt: "Distribution",
  },
  professionalService: {
    src: IconProfessionalService,
    alt: "Professional Service",
  },
  foodBanks: {
    src: IconFoodBanks,
    alt: "Food Banks",
  },
  otherIndustries: {
    src: IconOtherIndustries,
    alt: "Other Industries",
  },
  analysisAndDesign: {
    src: IconAnalysis,
    alt: "Analysis & Design",
  },
  development: {
    src: IconDevelopment,
    alt: "Development ",
  },
  projectManagement: {
    src: IconProjectManagement,
    alt: "Project Management",
  },
  implementation: {
    src: IconImplementation,
    alt: "Implementation",
  },
  trainingAndSupport: {
    src: IconTraining,
    alt: "Training & Support",
  },
  systemIntegration: {
    src: IconSystemIntegration,
    alt: "System Integration",
  },
  analysisAndDesignFill: {
    src: IconAnalysisFill,
    alt: "Analysis & Design",
  },
  developmentFill: {
    src: IconDevelopmentFill,
    alt: "Development ",
  },
  projectManagementFill: {
    src: IconProjectManagementFill,
    alt: "Project Management",
  },
  implementationFill: {
    src: IconImplementationFill,
    alt: "Implementation",
  },
  trainingAndSupportFill: {
    src: IconTrainingFill,
    alt: "Training & Support",
  },
  systemIntegrationFill: {
    src: IconSystemIntegrationFill,
    alt: "System Integration",
  },
}

/***************************************************
  Component
***************************************************/

export default ({ icon, text, className, to, ...props }) => {
  return (
    <div {...props} className={`icon-button ${className}`}>
      <img src={icon.src} alt={icon.alt} />
      {text && <span>{text}</span>}
      {to && (
        <Button to={to} color="teal">
          Find Out More
        </Button>
      )}
    </div>
  )
}
