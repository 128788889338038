/***************************************************
  Imports
***************************************************/

import React from "react"
import { Link } from "gatsby"

/***************************************************
  Component
***************************************************/

export default ({ color, type, children, to, target }) => {
  return (
    <>
      {type === "submit" ? (
        <button className={`button ${color && color}`}>{children}</button>
      ) : (
        <Link to={to} target={target} className={`button ${color && color}`}>
          {children}
        </Link>
      )}
    </>
  )
}
